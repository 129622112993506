<template>
  <Popup ref="popupRef" width="500px" :showConfirm="false" :showCancel="false" @close="resetForm">
    <div class="post-detail" v-if="postDetail">
      <div class="flex justify-between">
        <span class="name">{{postDetail.postTitle}}</span>
        <span class="price theme-text">{{postDetail.priceDesc}}</span>
      </div>
      <div class="flex between-center">
        <div class="tags">
          <div class="tag" v-for="(tag, index) in postDetail.tags" :key="index">{{tag}}</div>
        </div>
        <span class="theme-text" v-if="postDetail.bizLiftDesc">{{postDetail.bizLiftDesc}}</span>
      </div>
      <div class="title">职位详情</div>
      <div class="info-row" v-if="postDetail.workDate">
        <img src="@/assets/images/riqi.png">
        <span>工作日期：{{postDetail.workDate}}</span>
      </div>
      <div class="info-row" v-if="postDetail.workHour">
        <img src="@/assets/images/shijian.png">
        <span>工作时间：{{postDetail.workHour}}</span>
      </div>
      <div class="info-row" v-if="postDetail.locType == 0 && postDetail.locationDesc">
        <img src="@/assets/images/dizhi.png">
        <span>地址：{{postDetail.locationDesc}}</span>
      </div>
      <div class="info-row" v-if="postDetail.welfareDesc">
        <img src="@/assets/images/fuli.png">
        <span>福利：{{postDetail.welfareDesc}}</span>
      </div>
      <div class="title">工作描述</div>
      <div class="post-desc">{{postDetail.workContent}}</div>
      <div class="title">工作要求</div>
      <div class="info-row">
        <img src="@/assets/images/xueli.png">
        <span>学历：{{postDetail.eduRequire}}</span>
      </div>
      <div class="info-row">
        <img src="@/assets/images/xingbie.png">
        <span>性别：{{postDetail.sexRequire}}</span>
      </div>
      <div class="info-row">
        <img src="@/assets/images/shengao.png">
        <span>身高：{{postDetail.hgtRequire}}</span>
      </div>
      <div class="info-row">
        <img src="@/assets/images/nianling.png">
        <span>年龄：{{postDetail.ageRequire}}</span>
      </div>
      <div class="title">企业信息</div>
      <div class="merchant-info">
        <LoadImage class="mer-logo" :src="postDetail.merchantLogo"></LoadImage>
        <div>
          <div class="mer-name">{{postDetail.merchantName}}</div>
          <div>{{postDetail.merchantType == 0 ? '未认证': postDetail.merchantType == 1 ? '个人认证':'企业认证'}}</div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import { postDetailApi } from '@/api/post.js'
export default {
  components: {
    Popup,
    LoadImage
  },
  data() {
    return {
      pid: '',
      postDetail: null
    }
  },
  methods: {
    open(option) {
      if(option.pid) {
        this.pid = option.pid
        this.getPostDetail()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.pid = ''
      this.postDetail = null
    },
    getPostDetail() {
      console.log('this.pid', this.pid)
      postDetailApi({
        postId: this.pid
      }).then(res => {
        console.log('职位详情', res)
        this.postDetail = res.data
        this.$refs.popupRef.open()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.post-detail {
  font-size: 14px;
  .name {
    flex: 1;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .price {
    font-size: 16px;
    font-weight: bold;
  }
  .tags {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    .tag {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid rgba(0,0,0,.1);
      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }
  .title {
    font-size: 15px;
    font-weight: bold;
    margin: 40px 0 16px;
  }
  .info-row {
    display: flex;
    align-items: center;
    color: #666;
    margin-bottom: 8px;
    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
  .post-desc {
    color: #666;
    white-space: pre-wrap;
  }
  .merchant-info {
    display: flex;
    align-items: center;
    .mer-logo {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 8px;
    }
    .mer-name {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}
</style>